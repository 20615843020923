import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 지난달 도입한 카카오톡 기반 AI간편개통 서비스 기능 대폭 업그레이드… 자연어로 개통 관련 어려움 해결",
  "| 핀다이렉트 고객센터 내 개통 관련 불편 24시간 대응… 번호이동 개통 시 사전동의 불편 100% 해소, 고객센터 상담원 효율 70% 증진",
  "| 고객이 가장 불편하게 느껴왔던 개통 관련 해결사 역할… 고객우선 가치 지향",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/33/33_img1.png" style={{ maxWidth: "300px", margin: "auto" }} alt="Figure2" />
      </div>
      <div className="desc">&lt;스테이지파이브가 운영 중인 통신플랫폼 ‘핀다이렉트’ 로고&gt;</div>
    </div>
    <p>
      <br />
      <br />
      ㅣ 스테이지파이브(대표 서상원)은 운영 중인 통신 플랫폼 핀다이렉트(www.pindirect.com) 내내 AI간편개통에 자연어 처리 등이
      가능하도록 업그레이드하여 고객센터 서비스의 질을 향상시켰다고 25일 밝혔다.
      <br />
      <br />
      ㅣ 해당 AI간편개통 서비스는 번호이동 사전동의를 고객이 직접 해야하는 번거로운 절차를 자동으로 처리해주어 편의성을
      확대하였다. 이는 통신업계에서 전례 없었던 서비스이다. 본 서비스의 개발은 MVNO의 고객센터 수준을 끌어올려 고객들의 편의성을
      강화 및 증진할 수 있는 방법을 고민한 데에서 시작되었다.
      <br />
      <br />
      ㅣ 핀다이렉트 AI간편개통 서비스는 버튼 몇 번만으로도 개통관련 불편을 해결할 수 있도록 간소화한 UX/UI가 특징이다. 자연어로
      입력해도 개통불가나 개통신청서 수정 후 직접 개통까지 완료될 수 있도록 개발되어 24시간 대응이 가능하다.
      <br />
      <br />
      ㅣ 스테이지파이브는 해당 서비스를 타 통신사들도 쉽게 도입할 수 있도록 추진 중이다. AI간편개통 서비스가 확대된다면 그간
      업계에 고질적인 문제로 지적되어왔던 고객센터 품질 향상에 크게 기여할 수 있을 것이다.
      <br />
      <br />
      ㅣ스테이지파이브 관계자는 “AI간편개통 도입 전 상담원을 통해 많이 접수되었던 불편은 번호이동 개통 시 개통불가에 대한
      점이었다”며, “구체적으로는 ‘개통 시 사전동의 오류 및 방법 문의’, ‘개통신청서 내 납부정보 변경문의’ 순이었다.”고 밝혔다. 이어
      “상담원 연결하기 버튼을 누르고 한참을 기다려야 했던 업계 패러다임을 완전히 바꾸고자 해당 AI간편개통 서비스를 개발하고
      업그레이드 하였다.”고 말했다.
      <br />
      <br />
      ㅣ이처럼 스테이지파이브는 고객들이 체감 가능한 수준의 서비스 혁신을 위해 기술 기반 연구 및 도입 기조를 강화하고 있다. 경품
      등 마케팅 의존비중이 높은 통신시장에서 출혈경쟁보다는 고도화된 통신 플랫폼을 통해 고객편의를 증진하는 전략방향을 세웠기
      때문이다. 핀다이렉트 내 요금상품의 범위를 합리적인 Z요금제(무제한 요금제)부터 통신3사의 멤버십 혜택보다 더 많은 혜택을
      제공하는 프라임요금제까지 다양하게 구성한 이유가 여기에 있다. 이를 통해 산업 전체의 성장을 견인하는 사업자로 나아가는 것이
      스테이지파이브의 청사진이다.
      <br />
      <br />
      ㅣ 동사는 20년 9월에도 규제샌드박스 사업을 통해 업계 최초로 통신 가입 시 본인인증 수단으로 간편인증을 도입하는 등 그간
      고착되어 있었던 통신 서비스 분야 고객 경험의 본질적인 혁신에 도전하고 있다. 또한, 다양한 세그먼트를 타겟으로 한 통신
      디바이스를 기획 및 제작하고, 브랜디드 통신 플랫폼인 ‘핀다이렉트’를 선보이며 통신 내 여러 사업분야에서 두각을 나타내고 있다.
      <br />
    </p>
  </div>
)

const press27 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 4월 25일 조간 즉시배포 가능"
      pageInfo="(사진자료 메일 첨부)"
      title="스테이지파이브, 자사 통신플랫폼 핀다이렉트 내 AI간편개통 고도화 하여 고객편의성 강화… “개통 시 사전동의 불편 100% 해소, 고객센터 효율 70% 증진"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press27
